import React, { useRef, useEffect, useContext, useState } from "react";
import gsap from "gsap";

import { NavLink } from "components/nav/NavLink";
import { PrismicRichText } from "types/prismicRichtext";
import { prismicLinkResolver } from "utils/linkResolver";

import { UIContext } from "context/ui";
import { useResize } from "hooks/use-resize";

import s from "./NavMobile.scss";
import { ExpandableItem } from "./expandable-item/ExpandableItem";

interface IProps {
  items?: Array<IItemProps> | null;
  socialItems?: Array<ISocialItemProps> | null;
}

interface IItemProps {
  global_nav_li_label: PrismicRichText;
  global_nav_li_link: any;
}

interface ISocialItemProps {
  global_footer_social_li_label: PrismicRichText;
  global_footer_social_li_link: any;
}

const companyLinks = {
  title: {
    label: "Company"
  },
  items: [
    {
      label: "Safety",
      href: "/safety"
    },
    {
      label: "About Aurora",
      href: "/company"
    },
    {
      label: "Company Culture",
      href: "/culture"
    },
    {
      label: "Investor Relations",
      href: "https://ir.aurora.tech/"
    },
    {
      label: "Events",
      href: "https://info.aurora.tech/events"
    },
    {
      label: "Blog",
      href: "/blog"
    },
    {
      label: "Press / Media",
      href: "/press"
    }
  ]
};

export const NavMobile = ({ items, socialItems }: any) => {
  const { navOpen, navOpenFromHamburger, toggleNavFromHamburger } = useContext(
    UIContext
  );

  const links = items?.map((item: any, index: number) => {
    return (
      <li key={index} className={`${s.navMobile__listItem} | js-l-item`}>
        <NavLink name={item.label} to={item.destination} />
      </li>
    );
  });

  const socialLinks = socialItems?.map((item: any, index: number) => {
    return (
      <li
        key={index + items.length}
        className={`${s.navMobile__listItem} | js-l-item`}
      >
        <NavLink name={item.label} to={item.destination} />
      </li>
    );
  });

  const navRef = useRef<HTMLElement>(null);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const resize = useResize();
  const { sizes } = resize;

  useEffect(() => {
    if (navRef.current) {
      const items = navRef.current.querySelectorAll(".js-l-item");
      const x: number = navOpen ? 0 : -window.innerWidth;
      const navTransitionDuration =
        isFirstRender && !navOpenFromHamburger ? 0 : 1;
      gsap.to(navRef.current, {
        duration: navTransitionDuration,
        ease: "Power3.easeInOut",
        x
      });
      if (navOpen) {
        if (isFirstRender && !navOpenFromHamburger) {
          // no animation
        } else {
          gsap.set(items, { autoAlpha: 0, overwrite: true });
          gsap.to(items, {
            duration: 0.7,
            delay: 0.3,
            stagger: 0.1,
            autoAlpha: 1,
            overwrite: true
          });
        }
      } else {
        gsap.to(items, { duration: 0.5, autoAlpha: 0, overwrite: true });
      }

      setIsFirstRender(false);
      if (navOpenFromHamburger) toggleNavFromHamburger(false);
    }
  }, [navOpen]);

  useEffect(() => {
    !navOpen &&
      navRef.current &&
      gsap.set(navRef.current, { x: -window.innerWidth });
  }, [sizes]);

  return (
    <nav ref={navRef} className={s.navMobile}>
      <ul className={s.navMobile__list}>
        <li className={`${s.navMobile__listItem} | js-l-item`}>
          <NavLink name="Freight" to="/freight" />
        </li>
        <li className={`${s.navMobile__listItem} | js-l-item`}>
          <NavLink name="Rides" to="/rides" />
        </li>
        <li className={`${s.navMobile__listItem} | js-l-item`}>
          <NavLink name="Aurora Driver" to="/aurora-driver" />
        </li>
        <li className={`${s.navMobile__listItem} | js-l-item`}>
          <NavLink name="Careers" to="/careers" />
        </li>
        <li className={`${s.navMobile__listItem} | js-l-item`}>
          <NavLink name="News" to="/newsroom" />
        </li>
        <li className={`${s.navMobile__listItem} | js-l-item`}>
          <ExpandableItem list={companyLinks} />
        </li>
      </ul>
      <div className={s.bottomSection}>
        <ul className={s.navMobile__listBottom}>
          <li key="0" className={`${s.navMobile__listItem} | js-l-item`}>
            <NavLink name="Press" to="/press" />
          </li>
          {socialLinks}
        </ul>
        <div className={`${s.navMobile__copyright} | js-l-item`}>
          All Rights Reserved © 2023 Aurora Innovations Inc.
        </div>
      </div>
    </nav>
  );
};
