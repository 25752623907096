import React, { useRef, ChangeEvent, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import s from './NewsletterCTA.scss';

import ErrorIcon from 'assets/svg/error-icon.svg';
import gsap from 'gsap';
import DrawSVGPlugin from 'utils/DrawSVGPlugin.js';
gsap.registerPlugin(DrawSVGPlugin);

interface IProps {
  email: string;
  campaign: {
    campaignId: string;
  };
}

declare global {
  interface Window {
    newsletterApiResponse:string;
  }
}

export const NewsletterCTA = ({cta}: {cta: {label: string, href: string}}) => {
  return (
    <a id="newsletter" className={s.newsletter} href={cta.href} target='_blank'>

      <div className={s.newsletter__form}>
        <label htmlFor="email" className={s.newsletter__form}>
          <span className={`u-visually-hidden`}>Sign up for The Dashboard.</span>
          <div
            id="email"
            className={s('newsletter__email','newsletter-input-js')}
          >
            {cta.label}
          </div>
        </label>

        <div className={s.newsletter__btn}>
          <svg width="36" height="12" viewBox="0 0 36 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect transform="rotate(180) translate(-34.9, -11.5)" x="0" y="5" width="34.9" height="1.5" fill="black"/>
            <path d="M35.4,6.5c0.3-0.3,0.3-0.8,0-1.1l-4.8-4.8c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1L33.8,6l-4.2,4.2c-0.3,0.3-0.3,0.8,0,1.1s0.8,0.3,1.1,0L35.4,6.5z" fill="black"/>
          </svg>
        </div>
      </div>
    </a>
  );
};
