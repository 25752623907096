import React, { useContext, useEffect, useRef, useState } from 'react';
import gsap from 'gsap';

import s from './NavButton.scss';
import { UIContext } from 'context/ui';

export const NavButton = ({ style }) => {

  const { toggleNav, navOpen, toggleNavFromHamburger } = useContext(UIContext);

  const btnRef = useRef(HTMLButtonElement);
  const hamburgerLine1Ref = useRef(null);
  const hamburgerLine2Ref = useRef(null);
  const hamburgerLine3Ref = useRef(null);
  const closeLine1Ref = useRef(null);
  const closeLine2Ref = useRef(null);
  const closeLine2mRef = useRef(null);
  const closeLine3Ref = useRef(null);
  const closeLine4Ref = useRef(null);

  const [isFirstRender, setIsFirstRender] = useState(true)
  const [isNavOpen, setIsNavOpen] = useState(false);

  // from hamburger to close
  const fromHamburgerToClose = () => {
    gsap.fromTo(hamburgerLine1Ref.current, { width: 26 }, { duration: .5, width: 0, ease:"hamburger-easing", overwrite: true, delay: 0 });
    gsap.fromTo(hamburgerLine2Ref.current, { width: 26 }, { duration: .5, width: 0, ease:"hamburger-easing", overwrite: true, delay: 0 });
    gsap.fromTo(hamburgerLine3Ref.current, { width: 15 }, { duration: .5, width: 0, ease:"hamburger-easing", overwrite: true, delay: 0 });
    gsap.fromTo(closeLine1Ref.current, { height: 0 }, { duration: .4, height: 30, ease:"hamburger-easing", overwrite: true, delay: .5 });
    gsap.to(closeLine3Ref.current, { keyframes: [
      {duration: .4, height: 22, ease:"hamburger-easing", overwrite: true, delay: .8 },
      {duration: 0, height: 0}
    ]});
    gsap.fromTo(closeLine2Ref.current, { width: 0 }, { duration: .6, width: 30, ease:"hamburger-easing", overwrite: true, delay: .9 });

    btnRef.current.style.pointerEvents = 'none'
    setTimeout( () => { if(btnRef.current) btnRef.current.style.pointerEvents = 'all' }, 1500 )
  }

  // from close to hamburger
  const fromCloseToHamburger = () => {
    gsap.fromTo(closeLine2Ref.current, { width: 0 }, { duration: 0, width: 0, ease:"hamburger-easing", overwrite: true });
    gsap.fromTo(closeLine1Ref.current, { height: 26 }, { duration: .3, height: 0, ease:"hamburger-easing", overwrite: true });
    gsap.fromTo(closeLine2mRef.current, { width: 30 }, { duration: .3, width: 0, ease:"hamburger-easing", overwrite: true, delay: .1 });
    gsap.to(closeLine4Ref.current, { keyframes: [
      {duration: .4, height: 22, ease:"hamburger-easing", overwrite: true, delay: .05 },
      {duration: 0, height: 0}
    ]});
    gsap.fromTo(hamburgerLine1Ref.current, { width: 0 }, { duration: .5, width: 26, ease:"hamburger-easing", overwrite: true, delay: .2 });
    gsap.fromTo(hamburgerLine2Ref.current, { width: 0 }, { duration: .5, width: 26, ease:"hamburger-easing", overwrite: true, delay: .4 });
    gsap.fromTo(hamburgerLine3Ref.current, { width: 0 }, { duration: .5, width: 15, ease:"hamburger-easing", overwrite: true, delay: .6 });

    btnRef.current.style.pointerEvents = 'none'
    setTimeout( () => { if(btnRef.current) btnRef.current.style.pointerEvents = 'all' }, 1100 )
  }

  useEffect(()=>{

    if(isFirstRender && !navOpen){
      gsap.set(closeLine1Ref.current, { height: 0 })
      gsap.set(closeLine3Ref.current, { height: 0 })
      gsap.set(closeLine4Ref.current, { height: 0 })
      gsap.set(closeLine2Ref.current, { width: 0 })
      gsap.set(closeLine2mRef.current, { width: 0 })
    }

    if( !isFirstRender && isNavOpen != navOpen ) {
      setIsNavOpen(navOpen)
      navOpen ? fromHamburgerToClose() : fromCloseToHamburger()
    }

    if( isFirstRender && isNavOpen != navOpen ) {
      setTimeout( ()=> fromCloseToHamburger(), 850)
    }

    setIsFirstRender(false)

  }, [navOpen])
  
  return (
    <button
      onClick={ () => {
        toggleNavFromHamburger(true)
        toggleNav(!navOpen)
      }}
      className={s('navButton', { navOpen })}
      aria-label="main-menu"
      aria-controls="main-menu"
      aria-expanded={navOpen}
      style={style}
      ref={btnRef}
    >

      <svg className={s.navButton__hamburger} width="26" height="22" viewBox="0 0 26 22" fill="none">
        <g transform="translate(0 1)">
          <rect className={s.navButton__hamburger__line1} ref={hamburgerLine1Ref} y="0" width="26" height="1" fill="#F0793F"></rect>
          <rect className={s.navButton__hamburger__line2} ref={hamburgerLine2Ref} y="9" width="26" height="1" fill="#F0793F"></rect>
          <rect className={s.navButton__hamburger__line3} ref={hamburgerLine3Ref} y="18" width="15" height="1" fill="#F0793F"></rect>
        </g>
      </svg>

      <svg className={s.navButton__close} width="22px" height="23px" viewBox="0 0 22 23">
        <g transform="translate(0, 0)" fill="#F0793F" fillRule="nonzero">
          <rect className={s.navButton__close__line1} ref={closeLine1Ref} x="0" y="0" transform="matrix(0.7071 -0.7071 0.7071 0.7071 0 0)" width="1" height="30" fill="#F0793F"></rect>
          <rect className={s.navButton__close__line2} ref={closeLine2Ref} x="0" y="0" transform="matrix(-0.7071 .7071 -0.7071 -0.7071 22 0)" width="30" height="1" fill="#F0793F"></rect>
          <rect className={s.navButton__close__line2m} ref={closeLine2mRef} x="0" y="0" transform="matrix(0.7071 -0.7071 0.7071 0.7071 0 21)" width="30" height="1" fill="#F0793F"/>
          <rect className={s.navButton__close__line3} ref={closeLine3Ref} x="0" y="0" transform="matrix(-1.00 0.00 -0.00 -1.00 22 22)" width="1" height="0"fill="#F0793F"/>
          <rect className={s.navButton__close__line4} ref={closeLine4Ref} x="0" y="0"transform="matrix(-1.00 0.00 -0.00 -1.00 1 22.0)" width="1" height="0"fill="#F0793F"/>
        </g>
      </svg>

    </button>
  );
};
